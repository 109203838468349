<!-- 城市选择-->
<template>
  <div class="CitySelect">
    <div class="topFixed" :style="`top:${barToTop}px`" ref="topFixed">
      <p class="currentTitle">当前定位</p>
      <van-cell class="currentPosition">
        <template #title>
          <span class="custom-title">{{ currentCity.cityName }}</span>
          <van-tag
            class="tag"
            round
            color="#F3F4F5"
            text-color="#FF4848"
            size="large"
            @click="reLocation"
          >
            重新定位
          </van-tag>
        </template>
      </van-cell>
    </div>
    <!-- 索引列表 -->
    <van-index-bar
      :index-list="indexList"
      :sticky-offset-top="indexToTop"
      @select="indexSelect"
      ref="indexBar"
    >
      <template v-for="(ele, key) in cityList">
        <van-index-anchor :key="key" :index="key" />
        <van-cell
          v-for="item in ele"
          :key="item.cityId"
          :title="item.cityName"
          @click="nextPage(item)"
        />
      </template>
    </van-index-bar>
    <!-- 淡入字母显示 -->
    <LetterFadeIn ref="LetterFadeIn" :activeLetter="activeLetter" />
  </div>
</template>

<script>
import LetterFadeIn from "@/components/LetterFadeIn.vue";
import { getCityAndValidityList } from "@/api/api_home";
import { mapState } from "vuex";
import IndexScroll from "@/utils/indexScroll";
export default {
  name: "CitySelect",
  data() {
    return {
      barToTop: 0,
      indexToTop: 0, // 索引列表吸顶距顶部距离
      activeLetter: "A", // 当前高亮索引
      indexList: [],
      cityList: []
    };
  },
  computed: {
    ...mapState({
      isShowDownLoad: "isShowDownLoad",
      currentCity: "position"
    })
  },
  watch: {
    isShowDownLoad() {
      this.calcHeight();
    }
  },
  methods: {
    // 计算固定定位元素距顶部高度
    calcHeight() {
      this.barToTop = this.$tools.calcTopHeight();
      this.indexToTop = this.barToTop + this.$refs.topFixed.offsetHeight;
    },
    // 选择城市
    nextPage(item) {
      this.$store.commit("setpPosition", item);
      this.$router.back(-1);
    },
    // 选择索引
    indexSelect(item) {
      this.activeLetter = item;
      this.$refs.LetterFadeIn.letterClick();
      IndexScroll(item, this);
    },
    // 重新定位
    reLocation() {
      this.$Geolocation(position => {
        this.$store.commit("setpPosition", position);
        this.$toast("定位成功");
      });
    },
    // 获取城市列表
    getCityList() {
      getCityAndValidityList({ indexName: "cityValidity" }).then(res => {
        if (res.code === 0) {
          const ls = this.$tools.handleArrayClassify(
            res.data.info,
            "cityInitial"
          );
          this.cityList = ls.arr;
          this.indexList = ls.indexList;
        }
      });
    }
  },
  created() {
    this.getCityList();
  },
  mounted() {
    this.calcHeight();
  },
  components: { LetterFadeIn }
};
</script>
<style lang="scss">
.CitySelect {
  .topFixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0.44rem;
    z-index: 900;
    background: #fff;
  }
  .van-index-bar {
    margin-top: 0.8rem;
  }
  .currentTitle {
    font-size: 14px;
    font-weight: bold;
    line-height: 0.2rem;
    padding: 0.06rem 0.18rem;
  }
  .currentPosition {
    height: 0.48rem;
    .van-cell__title {
      display: flex;
      align-items: center;
      .tag {
        font-size: 0.12rem;
        font-weight: bold;
        margin-left: 0.16rem;
        padding: 0.05rem 0.08rem;
      }
    }
  }
}
</style>
